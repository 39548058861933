<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      title="客户"
    >
      <template slot="footer">
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>

      <search-sale-agent @submit="submitSearch" />

      <a-table
        size="middle"
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :pagination="false"
        :scroll="{ y: 350 }"
        row-key="id"
      >

        <span slot="actions" slot-scope="text, record">
          <a @click="switchUser(record.id)" v-if="record.effective">登录</a>
        </span>

      </a-table>

      <a-row class="count-tips">
        共 {{ data.length }} 条记录
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { findSaleAgents } from '@/api/user'
import { switchUser } from '@/api/switch_user'
import { getOriginalUserUid } from '@/utils/token'
import SearchSaleAgent from '@/views/users/sale_agents/Search'

export default {
  name: 'SaleAgents',
  components: {
    SearchSaleAgent
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: [],
      query: {
        page: 1,
        per_page: 100000 // TODO 分页
      }
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    columns() {
      return [
        {
          title: '客户名称',
          dataIndex: 'name'
        },
        {
          title: '操作',
          dataIndex: 'actions',
          key: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findSaleAgents(this.id, this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    switchUser(agentId) {
      switchUser({ agent_id: agentId, original_user_uid: getOriginalUserUid() }).then((res) => {
        if (res.code === 0) {
          location.href = res.data.redirect_url
        }
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>

<style lang="less" scoped>
.count-tips {
  padding-top: 20px;
}
</style>
